@import '../variables';

.text-edit {
    font-weight: bold;
}
.detail-place {
    .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
        margin: 0!important;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
        background-color: #ffffff;
        border-radius: 6px 6px 0 0;
        margin-left: 0!important;
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active{
        border-bottom: 2px solid #34C2C2;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #34C2C2;
    }
    .ant-tabs-tab{
        font-weight: 500;
    }
    .no-bold-label {
        label {
            font-family: "KozGoPr6", serif;
        }
        .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label{
            padding: 0!important;
        }
    }
}
.border-content {
    border: none!important;
    box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
    .image-detail {
        margin: 12px 0;
        padding: 0 12px;

        .custom-image {
            aspect-ratio: 4/3;
        }
    }

    .ant-form-item-label {
        text-align: left;
        margin: 0 12px;

        label {
            font-weight: 600;
        }
    }

    .ant-checkbox-group{
        width: 100%;
    }
    
    .label-radio{
        .text-radio{
            font-size: 10px;
            text-align: center;
        }
}
    .place-multi-dropdown {
        display: flex;
        flex-direction: row;
    }

    .ant-typography {
        padding: 4px 11px;
    }


    @media screen and (min-width: 1024px) {
        .place-date {
            width: 400px;
        }
    }
}