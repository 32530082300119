$border-radius: 4px;

$btn-primary-new: #34c2c2;
$btn-primary: #4587c0;
$btn-green: #46b147;
$button-primary-color: #006bb5;
$button-secondary-color: #ffffff;
$button-secondary-outline: #d9d9d9;
$button-black-color: rgba(0, 0, 0, 0.85);

$gray-1: #f2f2f2;
$gray-2: #f5f5f5;
$gray-3: #e0e0e0;
$gray-4: #999999;
$gray-5: #828282;
$gray-6: #d9d9d9;

$yellow-1: #fff8d4;
$yellow-2: #efcb0d;
$yellow-3: #e0c015;
$yellow-4: #f7ed37;

$green-1: #f6ffed;
$green-2: #a5d77e;
$green-3: #6fb780;
$green-4: #6fb760;
$green-5: #29990e;
$green-6: #e0f4e4;
$green-7: #2f9b4b;

$purple-1: #f2eeff;
$purple-2: #bca7ef;
$purple-3: #7a37a1;

$orange-1: #fff5ec;
$orange-2: #ffd9aa;
$orange-3: #f4a353;

$brown-1: #f1ebeb;
$brown-2: #b8aba3;
$brown-3: #843c0c;

$red-1: #fce6e6;
$red-2: #f59a9b;
$red-3: #ff4d4f;
$red-4: #ff2a00;
$red-5: #e32a1c;

$blue-1: #f5f7ff;

$pink-1: #fff1f0;

$light-blue-1: #e6f7ff;
$light-blue-2: #91d5ff;
$light-blue-3: #01a0dd;
$light-blue-4: #00d8d2;
$blue-4: #2563eb;

$background-white: #ffffff;
$background-button: #adadad;

$text-black: #444444;
$text-blue: #5dc3dc;
$text-grey: #727171;
$text-white: #ffffff;

$title-black: #595757;

//Places
$bg-table-header: #efefef;
$text-header-black: #000000;
$background-menu: #001529;

$border-btn: #40a9ff