@import '../variables';

.base-layout-container {
    .site-layout {
        background: $background-white;

        .ant-layout {
            background: $background-white;
        }

        .main-container {
            padding: 30px 55px;
        }

        .main-container-collapse {
            padding: 30px 55px;
        }
        @media (max-width: 768px) {
            .ml-100 {
                margin-left: 0px;
            }
        }
        @media (min-width: 768px) {
            .ml-100 {
                margin-left: 100px;
            }
        }

        .text-back {
            margin-left: 10px;
            color: #34c2c2;
        }

        .btn-search {
            padding: 0 22px 0 22px;
        }
        .text-confirm {
            padding: 0 18px 0 18px;
        }
        .ic-notification {
            color: $btn-primary;
        }
    }
    .hamburgerMenu {
        position: absolute;
        top: 0;
        width: 36px;
        height: 40px;
        z-index: 1000;
        margin-top: 62px;
        background: $background-menu;
        padding: 7px;
        margin-right: 15px;
    }
}
