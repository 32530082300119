.charger {
    .ant-form-item-label {
        text-align: left;

        label {
            font-weight: 600;
        }
    }
    .place-multi-dropdown {
        display: flex;
        flex-direction: row;
    }
    .create-charger-input {
        .ant-input-group-addon{
            background-color: white !important;
            border: none !important;
        }
    }
    .checkbox-start {
        .ant-checkbox + span{
            font-weight: bold;
            font-family: 'KozGoPr6-Bold', serif;
        }
    }
    .label-url {
        margin-left: 10px;
    }
    .name-bold {
        font-family: 'KozGoPr6-Bold', serif;
    }
    .ant-checkbox-group {
        width: 100%;
    }
    .button-row{
        margin-left: 10px
    }
    .ant-radio-wrapper {
        font-family: 'KozGoPr6', serif;
        font-weight: unset;
    }
    .ant-checkbox-wrapper {
        font-family: 'KozGoPr6', serif;
        font-weight: unset;
    }

    .content-no-required-1 {
        .ant-form-item-label label{
            margin-left: 24px!important;
        }
    }
    .ant-form-item-label>label.ant-form-item-required::before{
        content: url("../../images/place/ic-required.svg");
        transform: scale(1.8);
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        margin-right: 14px;
        font-size: 11px;
    }

    .label-radio{
        .ant-layout, .ant-layout *{
            text-align: center;
        }
        .text-radio{
            font-size: 10px;
            text-align: center;
        }
    }
}
