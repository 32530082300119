@import '../variables';
.back-img {
  background: none ;
  border: 0px ;
  width: 30px;
}

.back-img-right {
  margin-right: 8px !important;
}

.text-area-notification {
  margin-bottom: -18px;
}

.push-now {
  margin-right: 15px;
}

.detail-button-notification {
  border-radius: 4px;
  color: $btn-primary;
  border-color: $btn-primary;
  background-color: $blue-1;
}
.detail-button-notification:hover {
  border-radius: 4px;
  color: $btn-primary;
  border-color: $btn-primary;
  background-color: #c8d2f6;
}

.delete-button-notification {
  border-radius: 4px;
  color: red;
  background-color: $red-1;
  border-color: red;
}

.delete-button-notification:hover {
  border-radius: 4px;
  color: red;
  background-color: $red-2;
  border-color: red;
}