@import '~antd/dist/antd.css';
@import 'bootstrap';
@import 'variables';
@import 'desktop/baseLayout.scss';
@import 'desktop/listCharger';
@import 'desktop/login.scss';
@import 'desktop/createCharger';
@import 'desktop/createPlace';
@import 'charger/charger';
@import 'places/place';
@import 'desktop/listUser';
@import 'desktop/detailUser';
@import 'charger/ExportModal';
@import 'desktop/historyCharger';
@import 'charger/ModalSettingOutTime';
@import 'account/create';
@import 'notification/list';
@import 'home/index';
body {
    font-family: 'KozGoPr6', serif;
}

label {
    font-family: 'KozGoPr6-Bold', serif;
}

label {
    font-weight: bold;
}

@font-face {
    font-family: KozGoPr6;
    src: url('../fonts/KozGoPr6N.otf');
}

@font-face {
    font-family: KozGoPr6-Bold;
    src: url('../fonts/KozGoPr6N-Bold.otf');
}

@import 'places/detail';
.ant-sidebar-nav {
    max-height: calc(100% - 64px);
}

.site-default-header,
.site-sidebar {
    z-index: 10;
}

.sidebar-collapsed-toggle {
    top: 64px !important;
    .ant-layout-sider-zero-width-trigger {
        top: 0 !important;
    }
}

.section-collapsed {
    margin-left: 0 !important;
}

.content-collapsed {
    margin-left: 265px !important;
}

.ant-pagination-item {
    font-family: KozGoPr6, serif;
}

.breadcrumb-container {
    width: 100%;
    border-left: 3px solid $btn-primary-new;
}

.ant-breadcrumb {
    font-weight: bold;
    font-family: 'KozGoPr6-Bold', serif;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 5px;
    padding-left: 10px;
}

.ant-breadcrumb a {
    color: #000;
}

.ant-breadcrumb li:last-child a {
    color: #000;
}

.site-default-header {
    z-index: 1060;
}

.ant-message {
    z-index: 1061;
}

.btn {
    border-radius: $border-radius;
}

.ant-btn-primary {
    background: $btn-primary-new;
    border-color: $btn-primary-new;
    border-radius: $border-radius;
}

.btn-success,
.btn-success:focus {
    background: #e0f4e4;
    border-color: $green-4;
    border-radius: $border-radius;
    margin-right: 5px;
    color: #2f9b4b;
    transition-duration: 0.3s;
}

.btn-success:hover {
    color: white !important;
    background: $green-2 !important;
    border-color: $green-2;
}

.btn-details,
.btn-details:focus {
    background: #dce2f1;
    border-color: $blue-4;
    border-radius: $border-radius;
    margin-right: 5px;
    color: #d5d5dd;
    transition-duration: 0.3s;
}

.btn-details:hover {
    color: white !important;
    background: $blue-4 !important;
    border-color: $blue-4;
}

.btn-primary-outline {
    background-color: transparent;
    border-color: $btn-primary-new;
    color: $btn-primary-new;
    &:hover {
        background-color: $btn-primary-new;
        color: white;
    }
}

.btn-danger {
    border-radius: 4px;
    color: red;
    border-color: red;
}

.btn-danger:hover {
    color: white !important;
    background-color: red !important;
}

.btn-modal {
    border-radius: 50px;
    width: 120px;
}

.p-0 {
    padding: 0 !important;
}

.w-55px {
    width: 55px !important;
}

.submit-btn {
    margin-bottom: 50px;
    margin-top: 30px;
    width: 200px;
    flex-direction: row;
}

.cancel-change {
    border: none;
}

@media screen and (min-width: 581px) {
    .cancel-change {
        margin-right: 40px;
    }
}

@media screen and (max-width: 580px) {
    .btn-group-change {
        display: flex;
        flex-direction: column;
        align-items: center;
        .cancel-change {
            margin-bottom: 10px !important;
        }
        .btn-success {
            margin: 0;
        }
    }
}

.ant-pagination-item {
    font-family: KozGoPr6, serif;
    a {
        color: $btn-primary-new;
    }
}

.ant-pagination-item-active {
    a {
        color: #fff;
        background-color: $btn-primary-new;
    }
    a:hover {
        color: #fff !important;
        border-color: $btn-primary-new;
    }
}

@media (max-width: 480px) {
    @import 'mobile/baseLayout.scss';
    @import 'mobile/AdditionPlace.scss';
}

.border-content {
    border: 1px solid $btn-primary-new;
    border-radius: 8px;
    margin-top: 16px;
    padding: 25px 12px;
}

.border-shadow-content {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 8px;
    margin-top: 16px;
    padding: 25px 12px;
}

.text-btn-back {
    border-radius: 4px;
    border: 1px solid #6195a3;
    text-decoration: #6195a3;
}

.static-content {
    margin-top: 16px;
    padding-left: 30px;
}

.success-modal {
    text-align: center;
    align-content: center;
    .ant-modal-body {
        padding-bottom: 3rem;
        .title {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }
        img {
            margin: 30px;
        }
        .message {
            margin: 10px 10px;
        }
    }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #6195a3;
}

.mgb-0 {
    margin-bottom: 0 !important;
}

.content-no-required {
    text-align: left;
    label {
        margin-left: 10px !important;
    }
}

.btn-add-facility {
    margin-top: 20px;
    width: 200px;
    flex-direction: row;
}

.rbc-calendar {
    min-height: 600px;
}

.ant-modal-title {
    text-align: center;
    font-family: 'KozGoPr6-Bold', serif;
}

.tab-pane-title {
    text-align: left;
    font-family: 'KozGoPr6-Bold', serif;
    font-size: medium;
    margin-left: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
    .tab-pane-title {
        margin-left: -20px;
    }
}

@media screen and (min-width: 1500px) {
    .tab-pane-title {
        margin-left: 55px;
    }
}

.ant-modal-footer {
    text-align: center;
}

.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.ant-modal-wrap {
    z-index: 1060;
}

.ant-table-thead>tr>th {
    //background: $bg-table-header;
    text-align: center !important;
    color: $text-header-black;
}

.container-sidebar {
    padding: 30px 55px 30px 55px;
}

.menuSidebar {
    width: 22px;
    margin: 10px 0 16px 24px;
}

.ant-layout-header {
    padding: 0 24px 0 24px;
}

.mt-30 {
    margin-top: 30px;
}

.ant-table-thead>tr>th {
    background: #efefef !important;
}

//place
.icon-center {
    align-items: center;
    display: flex;
}

.border-btn {
    border-color: $btn-primary-new !important;
}

.onTab:focus,
.onTab:hover {
    box-shadow: #848884 0 2px 5px 1px;
}

.btn-modal-cancel {
    color: red;
    justify-content: center;
    background-color: $pink-1;
}

.modal-submit {
    width: 40%;
    color: $green-7;
    justify-content: center;
    background-color: $green-6;
    border-color: $green-7;
}

.btn-button-modal {
    align-items: center;
    display: flex;
    border-radius: 50px;
    font-family: 'KozGoPr6', serif;
}

.icon-center-container {
    align-items: center;
    display: flex;
    border-radius: 50px;
    font-family: 'KozGoPr6', serif;
}

.btn-rounded {
    border-radius: 50px;
}

.icon-center-container:hover {
    font-family: 'KozGoPr6-Bold', serif;
    color: $text-white;
    border-color: white;
    background-color: $btn-primary-new;
}

.icon-center-container:focus {
    font-family: 'KozGoPr6-Bold', serif;
    border-color: $green-6;
    background-color: $green-7;
    color: black;
}

.btn-icon {
    padding: 0 30px 0 30px;
}

.btn-no-icon {
    padding: 0 45px 0 45px;
}

.text-add {
    margin-left: 10px;
}

.text-add:hover {
    margin-left: 10px;
    border-color: $green-6;
}

.text-bold {
    font-family: 'KozGoPr6-Bold', serif;
}

//.custom-table {
//    .stop-btn {
//        border-radius: 4px;
//        color: $red-5;
//        border-color: $red-5;
//        background-color: $pink-1;
//    }
//}
.text-btn {
    color: $text-white;
}

.total-number-charger {
    font-size: 70px;
    text-align: center;
    height: 100%;
}

.ant-tabs-tab:hover {
    color: #34c2c2;
}

.ant-radio-checked .ant-radio-inner {
    border-color: $btn-primary-new;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: $btn-primary-new;
}

.ant-radio-inner::after {
    background-color: $btn-primary-new;
}

.list-detail-charger .my-charger .ant-row .ant-col:not(:first-child) {
    border-top: none;
}

.gm-ui-hover-effect {
    display: none !important;
}

.gm-style .gm-style-iw {
    padding: 7px;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.gm-style-iw-d {
    overflow: hidden;
    margin: 0px;
    padding: 0px;
}

.gm-style-iw-d {
    overflow-y: hidden;
    /* Hide vertical scrollbar */
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
}

.colored-circle {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -2px;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.5px;
    border-color: white;
    height: 15px;
    width: 15px;
}

.ant-form-item-label>label.ant-form-item-required::before {
    content: url("../images/place/ic-required.svg") !important;
    transform: scale(1.5);
}