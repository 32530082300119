@import '../variables';
.modal-setting{
    .text-title{
        font-weight: bold;
    }
    .ant-divider-horizontal {
        margin: 12px 0;
    }

    .ant-modal-body {
        padding: 12px 0;
    }

    .modal-content {
        padding: 24px;
    }

    .ant-divider {
        box-sizing: revert;
        border-top: 1px solid rgba(0, 0, 0, 1);
    }

    .btn-ok {
        background-color: $btn-green;
    }
    .group-setting{
        label{
            font-weight: normal;
        }
        label{
            font-family: 'KozGoPr6', serif;
        }
    }
}
