@import '../variables';

.detail-user {

  .ant-form-item-label {

    label {
      font-weight: 600;
    }
  }

  .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0!important;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
    background-color: #ffffff;
    border-radius: 6px 6px 0 0;
    margin-left: 0!important;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active{
    border-bottom: 2px solid #34C2C2;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #34C2C2;
  }
  .ant-tabs-tab{
    font-weight: 500;
  }
  .no-bold-label {
    label {
      font-family: "KozGoPr6", serif;
    }
    .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label{
      padding: 0!important;
    }
  }
}
