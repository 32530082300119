.place {
    .place-multi-dropdown {
        display: flex;
        flex-direction: row;
    }

    .content-no-required-1 {
        .ant-form-item-label label{
            margin-left: 24px!important;
        }
    }

    .ant-btn-primary {
        border-radius: 50px;
    }

    .ant-typography {
        padding: 4px 11px;
    }

    .textarea-place {
        min-height: 120px !important;
        margin-top: 20px;
    }

    .ant-radio-wrapper {
        font-family: 'KozGoPr6', serif;
        font-weight: unset;
    }

    @media screen and (min-width: 1024px) {
        .place-date {
            width: 400px;
        }
    }
    .button-row{
        margin-left: 10px
    }
    input[type=number], input[type=password], input[type=text] {
        height: 32px;
    }

    input:-webkit-autofill:disabled {
        -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
        -webkit-text-fill-color: rgba(0, 0, 0, 0.25);
    }

    .dynamic-delete-button {
        text-align: center;
        position: relative;
        top: 1px;
        margin: 0 8px;
        color: #999;
        font-size: 24px;
        cursor: pointer;
        transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
        color: #777;
    }

    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .ant-form-item-label>label.ant-form-item-required::before{
        content: url("../../images/place/ic-required.svg");
        transform: scale(1.8);
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        margin-right: 14px;
        font-size: 11px;
    }
}
